<template>
  <div
    :id="id"
    v-masonry
    class="trending__carousel mt-4"
    transition-duration="1s"
    fit-width="true"
    gutter="15"
    column-width=".trending__item"
    item-selector=".trending__item"
    :class="{ 'trending__carousel--hidden': hidden }"
    :horizontal-order="horizontalOrder"
  >
    <div
      v-for="item in items"
      :key="item._id || item.id"
      v-masonry-tile
      class="trending__item pb-2"
      @click="handleItemClick(item)"
    >
      <img
        v-if="isItemPreOrder(item)"
        class="position-absolute"
        src="@/assets/icons/pre-order-badge.svg"
        alt="pre-order now"
      />
      <img
        v-if="isProductDigitalOnly(item)"
        class="trending__item-digital-badge"
        src="@/assets/icons/pdf-badge.svg"
        alt="digital-format"
      />
      <CloudinaryImage
        :id="item._id || item.id"
        :url="getImage(item)"
        :alt="`trending-type-${item._id || item.id}`"
        :height="item.type === feedTypes.CREATORS ? '285px' : '160px'"
        :modifiers="{
          gravity: 'face',
          roundCorner: item.type === feedTypes.PRODUCTS ? '0' : '10'
        }"
        :style="{
          objectFit: 'cover',
          borderRadius: item.type === feedTypes.PRODUCTS ? '0' : '10px'
        }"
        width="160px"
      />
      <p class="mb-2 mt-2 trending__item-title">{{ getTitle(item) }}</p>
      <p>{{ getHandle(item) }}</p>
    </div>
  </div>
</template>

<script>
import { feedTypes } from './constants';
import CloudinaryImage from '@/components/Elements/Image';
import { isItemPreOrder } from '@/checkoutForm/lib/availability';
import { FallbackImageURL, ImageTypes } from '@/misc/constants';
import isItemHasOnlyDigitalFormat from '@/misc/isItemHasOnlyDigitalFormat';

export default {
  name: 'TrendingCarousel',

  components: {
    CloudinaryImage
  },

  props: {
    id: {
      type: String,
      default: 'trending-carousel'
    },
    horizontalOrder: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      feedTypes
    };
  },

  methods: {
    isItemPreOrder,
    getImage(item) {
      switch (item.type) {
        case feedTypes.CREATORS: {
          const creatorPhotos = this.$get(item, 'profile.photos', []);
          let photo = null;
          if (creatorPhotos.length) {
            photo =
              creatorPhotos.find((image) => image.role === ImageTypes.Main)
                ?.imageUrl ||
              creatorPhotos.find((image) => image.role === ImageTypes.Discover)
                ?.imageUrl;
          }
          return photo || FallbackImageURL;
        }
        case feedTypes.RECIPES:
          return this.$get(item, 'images[0].url', FallbackImageURL);
        case feedTypes.PRODUCTS:
          return this.$get(item, 'previewImages[0].url', FallbackImageURL);
        default:
          return FallbackImageURL;
      }
    },
    getTitle(item) {
      switch (item.type) {
        case feedTypes.CREATORS:
          return this.$get(item, 'profile.brandName', '');
        case feedTypes.RECIPES:
          return this.$get(item, 'name', '');
        case feedTypes.PRODUCTS:
          return this.$get(item, 'name', '');
        default:
          return '';
      }
    },
    getHandle(item) {
      switch (item.type) {
        case feedTypes.CREATORS:
          return `@${this.$get(item, 'profile.handle', '')}`;
        case feedTypes.RECIPES:
          return this.$get(item, 'product.name', '');
        case feedTypes.PRODUCTS:
          return `@${this.$get(item, 'creator.profile.handle', '')}`;
        default:
          return '';
      }
    },
    handleItemClick(item) {
      this.$emit('onItemClick', item);
    },
    isProductDigitalOnly(item) {
      if (item.type !== feedTypes.PRODUCTS) return false;
      return isItemHasOnlyDigitalFormat(item);
    }
  }
};
</script>

<style lang="scss" scoped>
.trending {
  &__item {
    max-width: 173px;
    width: 160px;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    word-break: break-word;

    &-title {
      font-family: 'Poppins-SemiBold', sans-serif;
    }

    &-digital-badge {
      position: absolute;
      width: 40px;
      right: 5px;
      top: 0;
    }
  }

  &__carousel {
    @media (max-width: $screen-width-lg) {
      margin: 0 auto;
    }

    &--hidden {
      position: absolute !important;
      top: 0;
      visibility: hidden;
      z-index: -1;
    }
  }
}
</style>
