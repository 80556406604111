import { FreeMode, Navigation } from 'swiper';

export const defaultConfig = {
  grabCursor: true,
  freeMode: true,
  modules: [FreeMode, Navigation],
  slidesPerView: 'auto',
  initialSlide: 0,
  spaceBetween: 10,
  imagesSize: {
    width: '210px',
    height: '210px'
  }
};

export const centeredConfig = {
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: 'auto',
  initialSlide: 1,
  slideActiveClass: 'carousel-active-item',
  spaceBetween: 20,
  imagesSize: {
    width: '200px',
    height: '200px'
  }
};

export const previewConfig = {
  grabCursor: true,
  freeMode: true,
  modules: [FreeMode, Navigation],
  slidesPerView: 'auto',
  spaceBetween: 20,
  initialSlide: 0,
  imagesSize: {
    width: '299px',
    height: '299px'
  }
};

export const creatorPreviewConfig = {
  grabCursor: true,
  freeMode: true,
  modules: [FreeMode, Navigation],
  slidesPerView: 'auto',
  initialSlide: 0,
  spaceBetween: 20,
  imagesSize: {
    width: '200px',
    height: '200px'
  }
};

export const creatorSocialsConfig = {
  grabCursor: true,
  freeMode: true,
  modules: [FreeMode, Navigation],
  slidesPerView: 'auto',
  initialSlide: 0,
  spaceBetween: 20,
  imagesSize: {
    width: '225px',
    height: '370px'
  }
};

export const creatorDiscoverConfig = {
  grabCursor: true,
  freeMode: true,
  modules: [FreeMode, Navigation],
  slidesPerView: 'auto',
  initialSlide: 0,
  spaceBetween: 20,
  imagesSize: {
    width: '225px',
    height: '125px'
  }
};

export default {};
