<template>
  <div class="bundle-panel">
    <div class="bundle-panel__title">
      <slot name="title" />
    </div>
    <b-card class="bundle-panel__content">
      <div class="bundle-panel__content-products">
        <template v-for="(product, index) in products">
          <div
            :key="`image-${index}`"
            class="bundle-panel__content-products-cotainer"
          >
            <img
              v-if="isProductDigitalOnly(product)"
              class="bundle-panel__content-products-cotainer-digital-badge"
              src="@/assets/icons/pdf-badge.svg"
              alt="digital-format"
            />
            <CloudinaryImage
              :id="`product-preview-${product._id}`"
              class="bundle-panel__content-products-cotainer-img"
              :url="getPreviewImage(product)"
              :alt="`product-preview-${product._id}`"
              :modifiers="{ gravity: 'face' }"
              @click="$emit('click', product)"
            />
          </div>
          <img
            v-if="!index"
            :key="`plus-${index}`"
            class="mx-3 bundle-panel__content-products-icon"
            src="@/assets/icons/plus.svg"
            alt="plus"
          />
        </template>
      </div>
      <div class="bundle-panel__content-text mt-4">
        Total price
        <span class="price">{{ formatMoney(totalPrice, currency) }}</span>
        <Button
          id="Add2Basket_recommendation"
          class="mt-4"
          size="sm"
          variant="secondary"
          :loading="addToBasketLoading"
          @click="handleAddBundleToBasketClick"
        >
          Add to basket
        </Button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Button from '../Elements/Button';
import CloudinaryImage from '@/components/Elements/Image';
import getPreviewImages from '@/misc/getPreviewImages';
import { FallbackImageURL } from '@/misc/constants';
import getFormatPrice from '@/misc/getFormatPrice';
import getDefaultProductFormat from '@/misc/getDefaultProductFormat';
import formatMoney from '@/misc/formatMoney';
import isItemHasOnlyDigitalFormat from '@/misc/isItemHasOnlyDigitalFormat';

export default {
  name: 'ProductBundlePanel',

  components: {
    Button,
    CloudinaryImage
  },

  props: {
    products: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      addToBasketLoading: false
    };
  },

  computed: {
    ...mapGetters({
      currency: 'config/currency',
      region: 'config/region'
    }),
    discountPrice() {
      return (this.totalPrice * 10) / 100;
    },
    totalPrice() {
      return this.products?.reduce((sum, item) => {
        const defaultFormat = getDefaultProductFormat(item);
        const { amount } = getFormatPrice(defaultFormat, this.region);
        return sum + amount;
      }, 0);
    }
  },

  methods: {
    ...mapMutations({
      ADD_ITEM: 'checkout/ADD_ITEM'
    }),
    formatMoney,
    getPreviewImage(product) {
      const previewImage = this.$get(getPreviewImages(product)[0], 'url', null);
      return previewImage || FallbackImageURL;
    },
    handleAddBundleToBasketClick() {
      this.addToBasketLoading = true;
      this.products.map((product) =>
        this.ADD_ITEM({
          product,
          discounted: true,
          productFormatId: getDefaultProductFormat(product)?._id
        })
      );
      setTimeout(() => {
        this.addToBasketLoading = false;
        this.$router.push('/basket');
      }, 650);
    },
    isProductDigitalOnly(product) {
      return isItemHasOnlyDigitalFormat(product);
    }
  }
};
</script>

<style lang="scss" scoped>
.bundle-panel {
  h4 {
    margin-top: 35px;
    margin-bottom: 15px;
    font-size: $font-size-extra-large;
    font-weight: $font-weight-demi-bold;
  }

  &__content {
    background-color: transparent;
    border: $dark-grey-color-light 1px solid;
    border-radius: 10px;

    &-products {
      display: flex;
      justify-content: center;

      &-cotainer {
        position: relative;

        &-img {
          cursor: pointer;
          width: 80px;
          height: 80px;

          @media (min-width: $screen-width-md) {
            width: 135px;
            height: 135px;
          }
        }

        &-digital-badge {
          position: absolute;
          width: 35px;
          right: 5px;
          top: 0;
        }
      }
    }

    &-text {
      text-align: center;
    }

    @media (min-width: $screen-width-md) {
      .card-body {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }

  .price {
    color: $primary-color;
  }
}
</style>
