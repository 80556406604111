<template>
  <div
    class="carousel"
    :style="cssVars"
    :class="{
      'carousel--creator': mode === CarouselModes.CREATOR_PREVIEW,
      'carousel--centered': isCenteredMode,
      'carousel--centered carousel--socials':
        mode === CarouselModes.CREATOR_SOCIALS,
      'carousel--preview': mode === CarouselModes.PREVIEW,
      'carousel--hover': isHoverMode
    }"
  >
    <div
      v-if="showHeader"
      class="carousel__header"
      :style="{
        paddingBottom: headerPaddingBottom
      }"
    >
      <div class="carousel__header-title">
        <slot name="title" />
      </div>
      <div v-if="seeAllLink" class="carousel__header-action">
        <NuxtLink :to="localePath(seeAllLink)"> See all </NuxtLink>
      </div>
      <div class="carousel__btn">
        <div :id="`${id}-carousel-prev`" class="carousel__btn-prev">&#60;</div>
        <div :id="`${id}-carousel-next`" class="carousel__btn-next ml-2">
          &#62;
        </div>
      </div>
    </div>
    <div v-if="descriptionVisible" class="carousel__description">
      <slot name="subtitle" />
    </div>
    <div :id="id" class="swiper">
      <div v-if="isPreview" class="swiper-wrapper">
        <div
          v-for="(image, index) in images"
          :key="image._id"
          class="swiper-slide carousel__item"
          @click="handleItemClick(image)"
        >
          <div class="carousel__item-img">
            <img
              v-if="isBannerVisible(index)"
              class="carousel__item-img__banner"
              src="@/assets/icons/pre-order-badge.svg"
              alt="banner"
            />
            <CloudinaryImage
              :id="`image-${image._id}`"
              class="carousel__item-img__picture"
              :url="image.url"
              :alt="`product-preview-${image._id}`"
              :modifiers="{
                gravity: 'face',
                roundCorner: imgBorderRadius
              }"
            />
          </div>
        </div>
      </div>
      <div v-else class="swiper-wrapper">
        <div
          v-for="item in items"
          :key="item.id"
          class="swiper-slide carousel__item"
          @click="handleItemClick(item)"
        >
          <div class="carousel__item-img">
            <img
              v-if="isItemPreOrder(item)"
              class="position-absolute"
              src="@/assets/icons/pre-order-badge.svg"
              alt="pre-order now"
            />
            <img
              v-if="isProductDigitalOnly(item)"
              class="carousel__item-digital-badge"
              src="@/assets/icons/pdf-badge.svg"
              alt="digital-format"
            />
            <img
              v-if="mode === CarouselModes.CREATOR_SOCIALS"
              :src="item.thumbnail_url"
              :alt="`post-${$get(item, 'title.short')}`"
              :width="cssVars['--img-width']"
              :height="cssVars['--img-height']"
            />
            <CloudinaryImage
              v-else
              :id="`product-${item.name}`"
              class="carousel__item-img__picture"
              :url="getPreviewImage(item)"
              :alt="`product-${item.name}`"
              :width="cssVars['--img-width']"
              :height="cssVars['--img-height']"
              :modifiers="{
                gravity: 'face',
                roundCorner: imgBorderRadius
              }"
            />
          </div>

          <div class="carousel__item-title">
            <slot name="itemTitle" :item="item">
              <div v-if="mode === CarouselModes.PRODUCT">{{ item.name }}</div>
            </slot>
          </div>
          <div class="carousel__item-subtitle">
            <slot name="itemSubtitle" :item="item">
              <ProductPrice
                v-if="mode === CarouselModes.PRODUCT"
                :item="item"
              />
            </slot>
          </div>
          <div class="carousel__item-action">
            <slot name="itemAction" :item="item">
              <div v-if="showDefaultItemActions">
                <Button
                  class="w-100"
                  full-width
                  :loading="isAddToBasketLoading"
                  variant="outline"
                  @click="handleAddToBasketClick(item)"
                >
                  Add to basket
                </Button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showSocialPost"
      centered
      size="sm"
      return-focus="carousel"
      :modal-footer="false"
      :hide-footer="true"
      content-class="social-post-modal"
      modal-class="social-post-dialog"
    >
      <b-overlay
        :show="isSocialLoading"
        opacity="1"
        spinner-variant="dark"
        class="social-post-modal__loader"
      >
        <iframe
          v-if="socialPost"
          :src="socialPost.postURL"
          :srcdoc="socialPost.html"
          class="social-post-modal__content"
          @load="onSocialLoad"
        ></iframe>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { Swiper, FreeMode } from 'swiper';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import {
  defaultConfig,
  centeredConfig,
  previewConfig,
  creatorPreviewConfig,
  creatorSocialsConfig
} from './carouselConfigs';
import { isItemPreOrder } from '@/checkoutForm/lib/availability';

import CloudinaryImage from '@/components/Elements/Image';
import ProductPrice from '@/components/ProductPrice';

import { CarouselModes, FallbackImageURL, ImageTypes } from '@/misc/constants';
import isItemHasOnlyDigitalFormat from '@/misc/isItemHasOnlyDigitalFormat';
import 'swiper/swiper-bundle.min.css';
import getDefaultProductFormat from '@/misc/getDefaultProductFormat';
import Button from '@/components/Elements/Button.vue';

export default {
  name: 'CarouselComponent',

  components: {
    Button,
    CloudinaryImage,
    ProductPrice
  },

  props: {
    id: {
      type: [String, Number],
      default: 'swiper',
      required: true
    },
    mode: {
      type: String,
      default: CarouselModes.DEFAULT,
      validator: (value) =>
        [
          CarouselModes.DEFAULT,
          CarouselModes.CENTERED,
          CarouselModes.PREVIEW,
          CarouselModes.CREATOR_PREVIEW,
          CarouselModes.CREATOR_SOCIALS,
          CarouselModes.PRODUCT
        ].includes(value)
    },
    items: {
      type: [Array, Object],
      default: () => []
    },
    images: {
      type: Array,
      default: () => []
    },
    seeAllLink: {
      type: String,
      default: ''
    },
    descriptionVisible: {
      type: Boolean,
      default: false
    },
    isPreOrder: {
      type: Boolean,
      default: false
    },
    showDefaultItemActions: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      swiper: null,
      CarouselModes,
      showSocialPost: false,
      socialPost: null,
      isSocialLoading: false,
      isAddToBasketLoading: false
    };
  },

  computed: {
    ...mapGetters({ redirectUrl: 'checkout/redirectUrl' }),
    swiperConfig() {
      switch (this.mode) {
        case CarouselModes.CENTERED:
          return centeredConfig;
        case CarouselModes.PREVIEW:
          return previewConfig;
        case CarouselModes.CREATOR_PREVIEW:
          return creatorPreviewConfig;
        case CarouselModes.CREATOR_SOCIALS:
          return creatorSocialsConfig;
        default:
          return defaultConfig;
      }
    },
    isCenteredMode() {
      return [CarouselModes.CENTERED, CarouselModes.CREATOR_SOCIALS].includes(
        this.mode
      );
    },
    isPreview() {
      return [CarouselModes.PREVIEW, CarouselModes.CREATOR_PREVIEW].includes(
        this.mode
      );
    },
    isHoverMode() {
      return [CarouselModes.CREATOR_SOCIALS, CarouselModes.DEFAULT].includes(
        this.mode
      );
    },
    showHeader() {
      return this.mode !== CarouselModes.CREATOR_PREVIEW;
    },
    cssVars() {
      return {
        '--img-width': this.swiperConfig.imagesSize.width,
        '--img-height': this.swiperConfig.imagesSize.height
      };
    },
    imgBorderRadius() {
      return [CarouselModes.PRODUCT, CarouselModes.CREATOR_PREVIEW].includes(
        this.mode
      )
        ? 0
        : 10;
    },
    headerPaddingBottom() {
      if (this.isHoverMode) {
        return this.descriptionVisible ? '5px' : '25px';
      }
      return this.descriptionVisible ? '10px' : '30px';
    }
  },

  mounted() {
    if (!this.isCenteredMode) {
      Swiper.use([FreeMode]);
    }
    this.swiper = new Swiper(`#${this.id}`, {
      ...this.swiperConfig,
      navigation: {
        nextEl: `#${this.id}-carousel-next`,
        prevEl: `#${this.id}-carousel-prev`,
        disabledClass: 'carousel__btn-disabled'
      }
    });
  },

  methods: {
    ...mapActions({
      createSession: 'checkout/createSession'
    }),
    ...mapMutations({
      ADD_ITEM: 'checkout/ADD_ITEM'
    }),
    isItemPreOrder,
    getPreviewImage(item) {
      if (item?.previewImages && item?.previewImages?.length) {
        return item?.previewImages[0]?.url;
      }
      const creatorPhotos = this.$get(item, 'profile.photos', []);
      let photo = null;
      if (creatorPhotos.length) {
        photo =
          creatorPhotos.find((image) => image.role === ImageTypes.Main)
            ?.imageUrl ||
          creatorPhotos.find((image) => image.role === ImageTypes.Discover)
            ?.imageUrl;
      }
      return photo || FallbackImageURL;
    },
    handleItemClick(item) {
      if (this.mode === CarouselModes.CREATOR_SOCIALS) {
        this.prepareSocialPostPreview(item);
        return;
      }
      this.$emit('click', { item, mode: this.mode });
    },
    prepareSocialPostPreview(item) {
      const iframeStyle = `
        <style>
          body {margin:0;}
          blockquote {margin:0 !important; border-radius: 9px; min-height: 720px; background-color: #fff;}
          blockquote section {visibility: hidden;}
        </style>
      `;
      this.socialPost = { ...item, html: iframeStyle + item?.html };
      this.showSocialPost = true;
      this.isSocialLoading = true;
    },
    onSocialLoad() {
      setTimeout(() => (this.isSocialLoading = false), 1000);
    },
    isBannerVisible(index) {
      return index === 0 && this.isPreOrder;
    },
    isProductDigitalOnly(item) {
      if (this.mode !== CarouselModes.PRODUCT) return false;
      return isItemHasOnlyDigitalFormat(item);
    },
    handleAddToBasketClick(item) {
      this.isAddToBasketLoading = true;
      this.ADD_ITEM({
        product: item,
        discounted: true,
        productFormatId: getDefaultProductFormat(item)?._id
      });
      setTimeout(() => {
        this.addToBasketLoading = false;
        this.$router.push('/basket');
      }, 650);
    }
  }
};
</script>

<style lang="scss" scoped>
$imgWidth: var(--img-width);
$imgHeight: var(--img-height);

// has to override default styles for case on blog page
#blog-products {
  .swiper-wrapper {
    .carousel__item {
      margin-right: 30px !important;
    }
  }
}

.carousel {
  &__header {
    padding-bottom: 30px;
    color: $light-grey-color;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-title {
      font-size: 24px;
    }

    &-action {
      a {
        color: $primary-color;
        font-size: $font-size-small;
      }
    }
  }

  &__description {
    padding-bottom: 30px;
  }

  &__item {
    cursor: pointer;
    width: $imgWidth;
    height: auto;
    display: flex;
    flex-direction: column;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    &-title {
      font-family: 'Poppins-SemiBold', sans-serif;
      padding-top: 15px;
      color: $white-color;
    }

    &-subtitle {
      padding-bottom: 15px;
      color: $white-color;
      font-size: $font-size-small;
    }

    &-action {
      margin-top: auto;

      &--default {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 0.5em;
        .product-price {
          justify-content: flex-end;
        }
      }
    }

    &-img {
      overflow: hidden;

      &__banner {
        position: absolute;
        left: 0;
        top: 0;
        width: 75px;
        height: 75px;
      }

      &__picture {
        width: 100%;
        height: $imgHeight;
        object-fit: cover;
      }
    }

    &-digital-badge {
      position: absolute;
      width: 50px;
      right: 10px;
      top: 0;
    }
  }

  &--centered {
    .swiper-wrapper {
      align-items: baseline;
    }
  }

  &--hover {
    img {
      padding: 5px;
    }

    .swiper {
      margin-left: -5px;
    }

    .carousel__item:hover img {
      transition: transform 0.2s;
      transform: scale(1.05);
    }
  }
}

.carousel--creator {
  .carousel__item {
    cursor: default;
  }
}
</style>
