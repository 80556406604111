<template>
  <div class="about-creator">
    <h2 class="mb-5">About this creator</h2>

    <b-row>
      <b-col class="mb-5" lg="3" md="4">
        <div class="about-creator__background">
          <CloudinaryImage
            id="about-creator"
            :url="getPreviewImage()"
            alt="about-creator"
            :width="200"
            :height="200"
            :modifiers="{ gravity: 'face', roundCorner: '16' }"
            class="about-creator__img"
          />
        </div>
      </b-col>
      <b-col lg="9" md="8">
        <h2 class="about-creator__name">
          {{ $get(creator, 'profile.brandName') }}
        </h2>

        <div class="d-flex justify-content-between flex-wrap">
          <NuxtLink
            class="about-creator__handle"
            :to="localePath(getLinkToCreator())"
          >
            @{{ $get(creator, 'profile.handle') }}
          </NuxtLink>

          <CreatorLinks
            v-if="$get(creator, 'platforms', []).length"
            source-type="recipe"
            :data="creator"
          />
        </div>

        <div
          v-if="$get(creator, 'profile.biography')"
          class="about-creator__description mt-4"
        >
          <ViewMore
            :text="$get(creator, 'profile.biography')"
            :lines="4"
            :line-height="22"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FallbackImageURL, ImageTypes } from '@/misc/constants';
import CloudinaryImage from '@/components/Elements/Image';
import CreatorLinks from '@/components/CreatorLinks';
import ViewMore from '@/components/ViewMore';

export default {
  name: 'AboutCreator',

  components: {
    CloudinaryImage,
    CreatorLinks,
    ViewMore
  },

  props: {
    creator: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    getLinkToCreator() {
      return `/creators/${
        this.$get(this.creator, 'profile.handle') ||
        this.$get(this.creator, 'id')
      }`;
    },
    getPreviewImage() {
      const creatorPhotos = this.$get(this.creator, 'profile.photos', []);
      let photo = null;
      if (creatorPhotos.length) {
        photo = creatorPhotos.find(
          (image) => image.role === ImageTypes.Main
        )?.imageUrl;
      }
      return photo || FallbackImageURL;
    }
  }
};
</script>

<style scoped lang="scss">
.about-creator {
  padding: 30px 20px;
  background-color: $dark-grey-color;
  border-radius: 10px;

  &__name {
    font-family: 'PPRader-Bold', sans-serif;
    text-transform: uppercase;
  }

  &__handle {
    color: $white-color;
    overflow-wrap: anywhere;
  }

  &__background {
    background-color: $primary-color;
    width: 200px;
    height: 200px;
    border-radius: 16px;
    transform: rotate(-4deg);

    img {
      transform: rotate(4deg);
    }
  }

  @media (max-width: $screen-width-md) {
    &__background {
      width: 175px;
      height: 175px;

      img {
        width: 175px;
        height: 175px;
      }
    }
  }

  &__description {
    line-height: 22px;
  }
}
</style>
