import backendURLs from '~/misc/backendURLs';

export default (axios) => {
  return axios
    .get(`${backendURLs.statistics}/creators/trending`, {
      params: {
        query: {
          $where: { 'profile.handle': { $ne: process.env.FOUND_US_HANDLE } }
        }
      }
    })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};
