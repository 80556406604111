<template>
  <div class="carousel" :style="cssVars">
    <div class="carousel__header pb-2">
      <div class="carousel__header-title">
        <slot name="title" />
      </div>
      <div v-if="seeAllLink" class="carousel__header-action">
        <NuxtLink :to="localePath(seeAllLink)"> See all </NuxtLink>
      </div>
      <div class="carousel__btn">
        <div :id="`${id}-carousel-prev`" class="carousel__btn-prev">&#60;</div>
        <div :id="`${id}-carousel-next`" class="carousel__btn-next ml-2">
          &#62;
        </div>
      </div>
    </div>
    <div v-if="descriptionVisible" class="carousel__description pb-3">
      <slot name="subtitle" />
    </div>
    <div :id="id" class="swiper pt-3 pb-2 px-1">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="swiper-slide carousel__item-container"
          @click="handleItemClick(item)"
        >
          <div class="w-100 h-100 position-relative cursor-pointer">
            <div
              class="carousel__item-background position-absolute"
              :style="{
                background: $get(backgrounds, `[${index}]`, 'transparent')
              }"
            />
            <div class="carousel__item">
              <CloudinaryImage
                :id="`product-${item.profile.handle}`"
                :url="getPreviewImage(item)"
                :alt="`product-${item.profile.handle}`"
                :width="80"
                :height="80"
                :modifiers="{ gravity: 'face', roundCorner: '5' }"
              />

              <div class="carousel__item-title">
                <div
                  v-if="$get(item, 'profile.handle')"
                  :style="getFontStyle(item)"
                >
                  {{ $get(item, 'profile.handle') }}
                </div>
              </div>

              <div class="carousel__item-followers">
                {{ getFollowersNumber(item) }} followers
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, FreeMode } from 'swiper';

import { creatorDiscoverConfig } from '../Carousel/carouselConfigs';
import CloudinaryImage from '@/components/Elements/Image';

import {
  FallbackImageURL,
  ImageTypes,
  CreatorBackgroundColors
} from '@/misc/constants';
import getRandomBackgrounds from '@/checkoutForm/lib/getRandomBackgrounds';
import formatFollowersNumber from '@/misc/formatFollowersNumber';
import 'swiper/swiper-bundle.min.css';

export default {
  name: 'CreatorCarouselComponent',

  components: {
    CloudinaryImage
  },

  props: {
    id: {
      type: [String, Number],
      default: 'creator-swiper'
    },
    items: {
      type: [Array, Object],
      default: () => []
    },
    seeAllLink: {
      type: String,
      default: ''
    },
    descriptionVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      swiper: null,
      CreatorBackgroundColors
    };
  },

  computed: {
    swiperConfig() {
      return creatorDiscoverConfig;
    },
    cssVars() {
      return {
        '--img-width': this.swiperConfig.imagesSize.width,
        '--img-height': this.swiperConfig.imagesSize.height
      };
    },
    backgrounds() {
      if (!process.client) return [];

      return getRandomBackgrounds(this.items);
    }
  },
  mounted() {
    Swiper.use([FreeMode]);
    this.swiper = new Swiper(`#${this.id}`, {
      ...this.swiperConfig,
      navigation: {
        nextEl: `#${this.id}-carousel-next`,
        prevEl: `#${this.id}-carousel-prev`,
        disabledClass: 'carousel__btn-disabled'
      }
    });
  },

  methods: {
    getPreviewImage(item) {
      const creatorPhotos = this.$get(item, 'profile.photos', []);
      let photo = null;
      if (creatorPhotos.length) {
        photo = creatorPhotos.find(
          (image) => image.role === ImageTypes.Main
        )?.imageUrl;
      }
      return photo || FallbackImageURL;
    },
    getFollowersNumber(item) {
      const followers = this.$get(item, 'platforms', []).reduce(
        (total, platform) => total + this.$get(platform, 'followers', 0),
        0
      );

      return formatFollowersNumber(followers);
    },
    getFontStyle(item) {
      const handleLength = this.$get(item, 'profile.handle').length;
      return {
        'font-size': handleLength < 13 ? '14px' : '12px'
      };
    },
    handleItemClick(creator) {
      this.$emit('click', creator);
    }
  }
};
</script>

<style lang="scss" scoped>
$imgWidth: var(--img-width);
$imgHeight: var(--img-height);

.carousel {
  &__header {
    color: $light-grey-color;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-title {
      font-size: 24px;
    }

    &-action {
      a {
        color: $primary-color;
        font-size: $font-size-small;
      }
    }
  }

  &__item-container,
  &__item-background {
    width: $imgWidth;
    height: $imgHeight;
  }

  &__item-container {
    &:hover {
      transition: transform 0.2s;
      transform: translateY(-5px);
    }
  }

  &__item-background {
    transform: rotate(-3deg);
    border-radius: 10px;
  }

  &__item {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: $dark-secondary-color;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    &-title {
      position: absolute;
      bottom: 60px;
      left: 15px;
      font-size: 14px;
      color: $white-color;
      font-family: 'Poppins-SemiBold', sans-serif;
      word-break: break-word;

      div {
        max-width: 110px;
        line-height: 1.1;
      }
    }

    &-followers {
      position: absolute;
      bottom: 14px;
      left: 15px;
      font-size: $font-size-smaller;
      color: $white-color;
    }

    img {
      position: absolute;
      border-radius: 5px;
      right: 15px;
      top: 22.5px;
      width: 80px;
      height: 80px;
    }
  }
}
</style>
