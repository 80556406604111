<template>
  <div class="filters">
    <a
      v-for="(type, index) in filters"
      :key="index"
      :class="{
        'filters-btn_active': activeFilter === type,
        'filters-btn_disabled': disabled
      }"
      class="btn filters-btn mr-2 mt-2"
      @click="!disabled && $emit('filter-change', type)"
    >
      {{ getFilterButtonTitle(type) }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'FilterButtons',

  props: {
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    },
    activeFilter: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getFilterButtonTitle(type) {
      switch (type) {
        case this.filters.CREATORS:
          return 'Creators';
        case this.filters.RECIPES:
          return 'Recipes';
        case this.filters.PRODUCTS:
          return 'Products';
        default:
          return 'View all';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/components';

.filters {
  &-btn {
    box-shadow: none;
    outline: 0;
    font-size: $font-size-semi-large;
    padding: 3px 10px;
    border-radius: $button-border-radius-sm;
    background-color: $highlight-secondary;
    color: $white-color;

    &_active {
      background-color: $white-color;
      color: $black-color;
    }

    &_disabled {
      background-color: $highlight-secondary;
      color: $white-color;
      cursor: not-allowed !important;
    }

    @media (max-width: $screen-width-xm) {
      font-size: $font-size-small;
    }
  }
}
</style>
