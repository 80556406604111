<template>
  <div class="recipe-grid">
    <h5 class="text-center mb-4">{{ title }}</h5>
    <div v-if="type === viewType.SIMPLE" class="recipe-grid__container">
      <div
        v-for="(recipe, index) in recipesItems"
        :key="recipe._id"
        class="recipe-grid__item mb-3 cursor-pointer"
        @click="$emit('click', recipe)"
      >
        <CloudinaryImage
          :id="`recipe-${index}`"
          class="recipe-grid__item-img"
          :url="getPreviewImage(recipe)"
          :alt="`recipe-${$get(recipe, 'name')}`"
          :modifiers="{ roundCorner: 10 }"
          width="110px"
          height="75px"
        />

        <span>{{ $get(recipe, 'name') }}</span>
      </div>
    </div>
    <div
      v-else-if="type === viewType.CUSTOM"
      class="recipe-grid__container-custom"
    >
      <div
        v-for="recipe in recipesItems"
        :key="recipe._id"
        class="recipe-grid__item mb-3 cursor-pointer"
        @click="$emit('click', recipe)"
      >
        <CloudinaryImage
          :id="`recipe-${$get(recipe, 'name')}`"
          class="recipe-grid__item-img"
          :url="getPreviewImage(recipe)"
          :alt="`recipe-${$get(recipe, 'name')}`"
          :modifiers="{ roundCorner: 10 }"
          width="173px"
          height="173px"
        />
        <span class="recipe-grid__item-title">{{ $get(recipe, 'name') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { take } from 'lodash';
import { FallbackImageURL } from '@/misc/constants';
import CloudinaryImage from '@/components/Elements/Image';
import { viewType } from '@/components/RecipeGrid/componentTypes';

export default {
  name: 'RecipeGrid',

  components: {
    CloudinaryImage
  },

  props: {
    title: {
      type: String,
      default: null
    },
    recipes: {
      type: Array,
      default: () => []
    },
    countItems: {
      type: [String, Number],
      default: 6
    },
    type: {
      type: String,
      default: viewType.SIMPLE
    }
  },

  data() {
    return {
      viewType
    };
  },

  computed: {
    recipesItems() {
      return take(this.recipes, this.countItems);
    }
  },

  methods: {
    getPreviewImage(item) {
      return this.$get(item, 'images[0].url', FallbackImageURL);
    }
  }
};
</script>

<style lang="scss" scoped>
$defaultWidth: 120px;
$defaultHeight: 75px;

$customWidth: 173px;
$customHeight: 150px;

.recipe-grid {
  h5 {
    color: $primary-color;
    font-family: 'Poppins-SemiBold', sans-serif;
  }

  &__container,
  &__container-custom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: $screen-width-xl) {
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: $defaultWidth;
    font-size: $font-size-small;
    line-height: 20px;
    text-align: center;

    &-img {
      margin-bottom: 10px;
      object-fit: cover;
      border-radius: 10px;
      height: $defaultHeight;
      width: 100%;
    }
  }

  &__container {
    margin: 0 -5px;
    .recipe-grid__item {
      padding: 5px;

      span {
        overflow-wrap: anywhere;
      }

      img {
        &:hover {
          transition: transform 0.2s;
          transform: scale(1.05);
        }
      }
    }
  }

  &__container-custom {
    margin: 0 -5px;

    .recipe-grid__item-title {
      position: absolute;
      border-radius: 0 0 10px 10px;
      background-color: $dark-secondary-color;
      height: 75px;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px 10px;
      font-family: 'Poppins-SemiBold', sans-serif;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .recipe-grid__item {
      position: relative;
      width: $customWidth;
      margin-right: 5px;
      margin-left: 5px;

      &-img {
        height: $customHeight;
        width: $customWidth;
      }

      &:hover {
        .recipe-grid__item-title {
          height: 85px;
          transition: height 0.3s;
        }
      }

      @media (max-width: $screen-width-xm) {
        width: 153px;
        margin: auto;

        &-img {
          height: 120px;
          width: 153px;
        }
      }
    }
  }
}
</style>
