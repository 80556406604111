import { last } from 'lodash';
import { CreatorBackgroundColors } from '../../misc/constants';

export default function (items) {
  const backgrounds = [];
  items.forEach(() => {
    let randomIndex = Math.floor(
      Math.random() * (CreatorBackgroundColors.length - 1)
    );
    while (CreatorBackgroundColors[randomIndex] === last(backgrounds)) {
      randomIndex = Math.floor(Math.random() * CreatorBackgroundColors.length);
    }
    backgrounds.push(CreatorBackgroundColors[randomIndex]);
  });

  return backgrounds;
}
