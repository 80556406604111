<template>
  <div class="product container">
    <b-row class="mt-4">
      <b-col md="5" class="d-none d-md-block">
        <ProductImages
          :images="previewImages"
          :is-pre-order="isPreOrder"
          :is-digital-format-only="isDigitalFormatOnly"
          :video-file="$get(product, 'videoFile')"
        />
      </b-col>
      <b-col md="7">
        <h1 class="product__title mb-2 mb-lg-4">
          {{ $get(product, 'name') }}
        </h1>

        <div class="product__creator-handle d-flex flex-wrap w-100 mb-2">
          <NuxtLink
            :to="
              localePath(`/creators/${$get(product, 'creator.profile.handle')}`)
            "
          >
            @{{ $get(product, 'creator.profile.handle') }}
          </NuxtLink>

          <CreatorLinks
            v-if="$get(product, 'creator.platforms', []).length"
            class="ml-auto"
            source-type="creator"
            :data="product.creator"
          />
        </div>

        <div class="d-md-none">
          <ProductImages
            :images="previewImages"
            :is-pre-order="isPreOrder"
            :is-digital-format-only="isDigitalFormatOnly"
            :video-file="$get(product, 'videoFile')"
          />
        </div>

        <PurchaseProduct
          v-if="product"
          is-product-page
          :product="product"
          allow-sticky-mode
          @onQuantityUpdate="setQuantity"
        />

        <div v-if="isBannerEnabled" class="banner">
          <AdBanner type="half" />
        </div>
      </b-col>
    </b-row>

    <div class="mt-4">
      <ProductBundlePanel
        v-if="recommended[0]"
        :products="[product, recommended[0]]"
        @click="handleProductBundleClick"
      >
        <template #title>
          <h4>Frequently bought together</h4>
        </template>
      </ProductBundlePanel>

      <div
        v-if="$get(product, 'contributors', []).length"
        class="product__contributors"
      >
        <h2 class="mt-5">All contributors</h2>
        <div class="product__contributors-items">
          <PreviewImage
            v-for="creator in $get(product, 'contributors')"
            :key="creator.id"
            :item="creator"
            type="creators"
            @click="handleCreatorClick"
          />
        </div>
      </div>

      <div
        v-if="
          $get(product, 'availability') !== productAvailability.PreOrder &&
          recipes &&
          recipes.length
        "
      >
        <h2 class="mt-5">Featured Content</h2>
        <RecipeGrid
          :type="viewType.CUSTOM"
          :count-items="6"
          :recipes="recipes"
          @click="handleRecipeClick"
        />
      </div>

      <AboutCreator class="my-4" :creator="$get(product, 'creator')" />

      <Carousel
        v-if="filteredCreatorProducts && filteredCreatorProducts.length"
        id="creatorProducts"
        class="my-4"
        :items="filteredCreatorProducts"
        :mode="CarouselModes.PRODUCT"
        show-default-item-actions
        @click="handleProductBundleClick"
      >
        <template #title>
          <h2>More from {{ $get(product, 'creator.profile.brandName') }}</h2>
        </template>
      </Carousel>

      <SimilarTopics v-if="productTags.length" :tags="productTags" />

      <CreatorCarousel
        v-if="creators && creators.length"
        id="discoverCreators"
        class="mt-4"
        :items="creators"
        @click="handleCreatorClick"
      >
        <template #title>
          <h2>Discover creators</h2>
        </template>
      </CreatorCarousel>

      <TrendingBlock v-if="trendingFeed" class="mt-5" :items="trendingFeed" />
    </div>
  </div>
</template>

<script>
import { get, inRange } from 'lodash';
import { mapGetters, mapState } from 'vuex';

import PurchaseProduct from '@/components/PurchaseProduct';
import SimilarTopics from '@/components/SimilarTopics';
import PreviewImage from '@/components/PreviewItems/PreviewImage';
import ProductBundlePanel from '@/components/ProductBundlePanel';
import AboutCreator from '@/components/AboutCreator';
import TrendingBlock from '@/components/TrendingBlock/Index';
import Carousel from '@/components/Carousel/Index';
import CreatorCarousel from '@/components/CreatorCarousel';
import RecipeGrid from '@/components/RecipeGrid/Index';

import {
  fetchOneProduct,
  fetchProductsByCreator,
  fetchProductsRecommended
} from '@/services/products';
import getPreviewImages from '@/misc/getPreviewImages';
import { viewType } from '@/components/RecipeGrid/componentTypes';
import { fetchCreatorsTrending } from '@/services/statistics';
import { fetchTrendingFeed } from '@/services/selections';
import { productAvailability } from '@/checkoutForm/lib/constants';
import ProductImages from '@/components/ProductImages';
import { CarouselModes, ProductAvailability } from '@/misc/constants';
import getDefaultProductFormat from '@/misc/getDefaultProductFormat';
import formatMoney from '@/misc/formatMoney';
import getFormatPrice from '@/misc/getFormatPrice';
import filterProductsByRegion from '@/misc/filterProductByRegion';
import isItemHasOnlyDigitalFormat from '@/misc/isItemHasOnlyDigitalFormat';
import { trackProductViewedEvent } from '@/lib/analytics';
import AdBanner from '@/components/AdBanner/index.vue';
import CreatorLinks from '@/components/CreatorLinks/index.vue';

export default {
  name: 'OneProduct',

  components: {
    CreatorLinks,
    AdBanner,
    ProductImages,
    SimilarTopics,
    PreviewImage,
    PurchaseProduct,
    ProductBundlePanel,
    AboutCreator,
    TrendingBlock,
    Carousel,
    CreatorCarousel,
    RecipeGrid
  },

  asyncData({ $axios, route, error, store, redirect }) {
    if (!route.params?.id) {
      redirect('/');
    }

    return fetchOneProduct($axios, route.params?.id, {
      query: {
        $populate: [
          { path: 'creator' },
          { path: 'contributors' },
          { path: 'videoFile' }
        ]
      }
    })
      .then((product) => {
        return { product };
      })
      .catch((err) => {
        const errorCode = get(err, 'response.data.code');
        error({ statusCode: inRange(errorCode, 400, 405) ? 404 : 500 });
        return { product: {} };
      });
  },

  data() {
    return {
      CarouselModes,
      productAvailability,
      trendingFeed: null,
      recommended: [],
      creatorProducts: [],
      recipes: [],
      creators: [],
      viewType,
      quantity: 1
    };
  },

  head() {
    const title = `${this.product?.name} from @${this.product?.creator?.profile?.handle} | Found it!`;

    return {
      title,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.mainImage
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: this.mainImage
        },
        {
          hid: 'description',
          name: 'description',
          content: `${this.product.shortDescription} | Found it!\nYou can also discover other book titles and recipes, from our top social creators.`
        }
      ]
    };
  },

  computed: {
    ...mapState('config', ['config']),
    ...mapState('main', ['menuVisible', 'mobileKeyboardVisible']),
    ...mapGetters({
      currency: 'config/currency',
      region: 'config/region',
      isBannerEnabled: 'config/isBannerEnabled'
    }),
    filteredCreatorProducts() {
      return filterProductsByRegion(this.creatorProducts, this.region);
    },
    previewImages() {
      return getPreviewImages(this.product);
    },
    isPreOrder() {
      return this.product?.availability === ProductAvailability.PreOrder;
    },
    mainImage() {
      return this.$get(this.previewImages, '[0].url', '');
    },
    productTags() {
      return this.$get(this.product, 'tags', []);
    },
    isDigitalFormatOnly() {
      return isItemHasOnlyDigitalFormat(this.product);
    }
  },

  mounted() {
    fetchProductsByCreator(this.$axios, this.product?.creator?.id).then(
      (response) => {
        const currentBookId = this.$route.params?.id;
        this.creatorProducts = this.$get(
          response,
          'creatorProducts',
          []
        )?.filter(({ id }) => id !== currentBookId);
      }
    );
    fetchCreatorsTrending(this.$axios).then((response) => {
      this.creators = response?.data;
    });

    // Removing recipes call at all TODO: bring back on request
    // if (
    //   this.$get(this.product, 'availability') !== productAvailability.PreOrder
    // ) {
    //   fetchRecipesForOneProduct(this.$axios, this.$route.params.id).then(
    //     (products) => {
    //       this.recipes = products?.data;
    //     }
    //   );
    // }

    const { storeRegion, locale } = this.config || {};

    fetchProductsRecommended(this.$axios, this.$route.params.id, {
      query: { language: locale, country: storeRegion }
    }).then((products) => {
      this.recommended = filterProductsByRegion(products?.data, this.region);
    });

    fetchTrendingFeed(this.$axios).then((trendingFeed) => {
      this.trendingFeed = trendingFeed;
    });

    trackProductViewedEvent({ product: this.product });
  },

  methods: {
    handleProductBundleClick(product) {
      if (product.id || product.item) {
        this.$router.push({
          path: `/products/${product.id || product.item.id}`
        });
      }
    },
    handleCreatorClick(creator) {
      this.$router.push({
        path: `/creators/${
          this.$get(creator, 'profile.handle') ||
          this.$get(creator, 'item.profile.handle') ||
          this.$get(creator, 'id')
        }`
      });
    },
    handleRecipeClick(recipe) {
      this.$router.push({
        path: `/recipe/${this.$get(recipe, '_id')}`
      });
    },
    setQuantity(quantity) {
      this.quantity = quantity;
    },
    getProductFormatPrice(product) {
      const defaultFormat = getDefaultProductFormat(product);
      return formatMoney(
        getFormatPrice(defaultFormat, this.region)?.amount,
        this.currency
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.product {
  &__title {
    font-size: $font-size-extra-large;
    font-family: 'Poppins-Bold', sans-serif;

    @media (min-width: $screen-width-lg) {
      font-size: 32px;
    }
  }

  &__creator-handle {
    font-size: $font-size-semi-large;
    font-family: 'Poppins-SemiBold', sans-serif;

    a {
      color: white;
    }
  }

  .banner {
    @media (min-width: $screen-width-lg) {
      margin-top: 70px;
    }
  }

  &__contributors {
    &-items {
      display: flex;
      flex-wrap: wrap;

      .preview-image::v-deep {
        margin-right: 5px;
        margin-left: 5px;
      }

      @media (max-width: $screen-width-xm) {
        .preview-image::v-deep {
          width: 153px;
          margin: auto;

          img {
            width: 153px;
            height: 153px;
          }
        }
      }

      @media (max-width: $screen-width-lg) {
        justify-content: center;
      }
    }
  }
}
</style>
