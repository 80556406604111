<template>
  <span class="product-price">
    <slot name="price" :price-amount="formattedPriceAmount">
      <span class="product-price__amount" :class="amountClassName">
        {{ formattedPriceAmount }}
      </span>
    </slot>
    <slot
      v-if="isTaxLabelVisible"
      name="tax"
      :tax-behaviour="price.taxBehaviour"
      :is-inclusive="price.taxBehaviour === 'inclusive'"
      :is-exclusive="price.taxBehaviour === 'exclusive'"
    >
      <span class="product-price__tax" :class="taxClassName">
        +{{ $t('tax') }}
      </span>
    </slot>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import getDefaultProductFormat from '@/misc/getDefaultProductFormat';
import getFormatPrice from '@/misc/getFormatPrice';
import formatMoney from '@/misc/formatMoney';

export default {
  props: {
    item: {
      type: Object
    },
    amountClassName: {
      type: String
    },
    taxClassName: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      currency: 'config/currency',
      region: 'config/region'
    }),
    defaultProductFormat() {
      return getDefaultProductFormat(this.item);
    },
    price() {
      return getFormatPrice(this.defaultProductFormat, this.region);
    },
    priceAmount() {
      return this.price.amount;
    },
    formattedPriceAmount() {
      return formatMoney(this.priceAmount, this.currency);
    },
    isTaxLabelVisible() {
      return this.price.taxBehaviour === 'exclusive';
    }
  }
};
</script>

<style lang="scss">
.product-price {
  display: inline-flex;
  line-height: 0.7;
  flex-grow: 1;
  justify-content: center;

  &__amount {
    font-size: 1.3em;
  }

  &__tax {
    display: flex;
    align-items: end;
    font-size: 0.8em;
    white-space: nowrap;
  }
}
</style>
