import getDefaultProductFormat from './getDefaultProductFormat';
import { ProductFormatFulfilment } from './constants';

export default function isItemHasOnlyDigitalFormat(product) {
  const productFormat = getDefaultProductFormat(product);
  if (!productFormat?.fulfilment) return false;
  if (productFormat.fulfilment === ProductFormatFulfilment.Digital) {
    return true;
  }
  return false;
}
