<template>
  <div class="trending position-relative">
    <h2>What’s trending</h2>

    <FilterButtons
      :filters="feedTypes"
      :active-filter="activeFilter"
      class="trending__filters-buttons mt-4"
      :disabled="!filterButtonsEnabled"
      @filter-change="handleFilterClick"
    />

    <TrendingCarousel
      :hidden="!horizontalOrder"
      :horizontal-order="true"
      :items="filteredItems"
      @onItemClick="handleItemClick"
    />
    <TrendingCarousel
      id="trending-carousel-all"
      :hidden="horizontalOrder"
      :horizontal-order="false"
      :items="filteredItems"
      @onItemClick="handleItemClick"
    />

    <div class="text-center pb-5 pt-3">
      <Button
        v-if="localItemsIsEmpty"
        variant="outline"
        size="sm"
        @click="handleShowMoreClick"
      >
        View more
      </Button>
    </div>
  </div>
</template>

<script>
import { size, cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import { feedTypes } from './constants';
import TrendingCarousel from './TrendingCarousel';
import { isItemPreOrder } from '@/checkoutForm/lib/availability';
import Button from '@/components/Elements/Button';
import FilterButtons from '@/components/Elements/FilterButtons';
import getDefaultProductFormat from '@/misc/getDefaultProductFormat';

const initialView = [
  feedTypes.PRODUCTS,
  feedTypes.CREATORS,
  feedTypes.RECIPES,
  feedTypes.RECIPES,
  feedTypes.CREATORS,
  feedTypes.PRODUCTS,
  feedTypes.PRODUCTS,
  feedTypes.CREATORS,
  feedTypes.RECIPES,
  feedTypes.RECIPES,
  feedTypes.CREATORS,
  feedTypes.PRODUCTS
];

export default {
  name: 'TrendingBlock',

  components: {
    Button,
    FilterButtons,
    TrendingCarousel
  },

  props: {
    items: {
      type: Object,
      default: () => {
        return {
          creators: [],
          products: [],
          recipes: []
        };
      }
    }
  },

  data() {
    return {
      initialData: [],
      localItems: [],
      activeItems: [],
      maxSize: 0,
      activeFilter: feedTypes.ALL,
      feedTypes,
      filterButtonsEnabled: true
    };
  },

  computed: {
    ...mapGetters({
      currency: 'config/currency',
      region: 'config/region'
    }),
    filteredItems() {
      const allItems = this.activeItems.filter((item) => {
        if (item.type === feedTypes.PRODUCTS) {
          const defaultFormat = getDefaultProductFormat(item);
          return !!defaultFormat?.prices?.find(
            (price) => price?.region === this.region
          );
        }
        return item;
      });
      return this.activeFilter === feedTypes.ALL
        ? allItems
        : allItems.filter((item) => item.type === this.activeFilter);
    },
    localItemsIsEmpty() {
      return Object.keys(this.localItems)
        .map((key) => size(this.localItems[key]))
        .every((el) => el);
    },
    horizontalOrder() {
      return this.activeFilter !== feedTypes.ALL;
    }
  },

  created() {
    this.initialData.push(...initialView);
    this.localItems = cloneDeep(this.items);
    this.addItemsToShow();
  },

  methods: {
    isItemPreOrder,
    handleFilterClick(type) {
      this.filterButtonsEnabled = false;
      this.activeFilter = type;
      // disable buttons until animation is not finished
      setTimeout(() => {
        this.filterButtonsEnabled = true;
      }, 1100);
    },
    getRandomItemAndShift(type) {
      return this.localItems?.[type]?.shift();
    },
    addItemsToShow() {
      this.initialData.forEach((key) => {
        const randomItem = this.getRandomItemAndShift(key);
        if (randomItem) {
          this.activeItems.push({
            ...randomItem,
            imageError: false,
            type: key
          });
        }
      });
      this.initialData = [];
    },
    handleShowMoreClick() {
      this.initialData.push(...initialView);
      this.addItemsToShow();
    },
    handleItemClick(item) {
      switch (item.type) {
        case feedTypes.RECIPES:
          this.$router.push({
            path: `/recipe/${this.$get(item, '_id')}`
          });
          break;
        case feedTypes.PRODUCTS:
          this.$router.push({ path: `/products/${item?.id}` });
          break;
        case feedTypes.CREATORS:
          this.$router.push({ path: `/creators/${item?.id}` });
          break;
        default:
      }
    }
  }
};
</script>
