<template>
  <div
    class="preview-image"
    :class="{
      'preview-image__product': typeConstant.PRODUCTS === type
    }"
    @click="$emit('click', item)"
  >
    <img
      v-if="isItemPreOrder(item)"
      class="position-absolute"
      src="@/assets/icons/pre-order-badge.svg"
      alt="pre-order now"
    />
    <img
      v-if="isProductDigitalOnly(item)"
      class="preview-image__product-digital-badge"
      src="@/assets/icons/pdf-badge.svg"
      alt="digital-format"
    />
    <CloudinaryImage
      :id="item._id"
      class="preview-image__img"
      :url="getPreviewImage(item)"
      :alt="`name-${$get(item, '_id')}`"
      :modifiers="{
        gravity: 'face',
        roundCorner: typeConstant.PRODUCTS === type ? 0 : '10'
      }"
      width="170px"
      height="170px"
    />

    <span class="mb-3 text-overflow">{{ getItemName(item) }}</span>
  </div>
</template>

<script>
import { FallbackImageURL, ImageTypes } from '@/misc/constants';
import CloudinaryImage from '@/components/Elements/Image';
import { isItemPreOrder } from '@/checkoutForm/lib/availability';
import isItemHasOnlyDigitalFormat from '@/misc/isItemHasOnlyDigitalFormat';

const type = {
  CREATORS: 'creators',
  PRODUCTS: 'products'
};

export default {
  name: 'PreviewImage',

  components: {
    CloudinaryImage
  },

  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: type.CREATORS
    }
  },

  data() {
    return {
      typeConstant: type
    };
  },

  methods: {
    isItemPreOrder,
    getPreviewImage(item) {
      if (item?.previewImages && item?.previewImages?.length) {
        return item?.previewImages[0]?.url;
      }
      const creatorPhotos = this.$get(item, 'profile.photos', []);
      let photo = null;
      if (creatorPhotos.length) {
        photo =
          creatorPhotos.find((image) => image.role === ImageTypes.Main)
            ?.imageUrl ||
          creatorPhotos.find((image) => image.role === ImageTypes.Discover)
            ?.imageUrl;
      }
      return photo || FallbackImageURL;
    },
    getItemName(item) {
      const handle = this.$get(item, 'profile.handle')
        ? `@${this.$get(item, 'profile.handle')}`
        : this.$get(item, 'name');
      return handle?.length >= 17 ? handle.substring(0, 17) + '...' : handle;
    },
    isProductDigitalOnly(product) {
      if (this.typeConstant.PRODUCTS === this.type) {
        return isItemHasOnlyDigitalFormat(product);
      }
      return false;
    }
  }
};
</script>

<style scoped lang="scss">
$defaultWidth: 170px;

.preview-image {
  display: flex;
  flex-direction: column;
  width: $defaultWidth;
  font-size: $font-size-small;
  line-height: 20px;
  cursor: pointer;

  &__img {
    margin-bottom: 10px;
    object-fit: cover;
    border-radius: 10px;
  }

  &__product {
    position: relative;

    .preview-image__img {
      border-radius: 0;
    }

    &-digital-badge {
      position: absolute;
      width: 40px;
      right: 5px;
      top: 0;
    }
  }
}
</style>
