var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carousel",class:{
    'carousel--creator': _vm.mode === _vm.CarouselModes.CREATOR_PREVIEW,
    'carousel--centered': _vm.isCenteredMode,
    'carousel--centered carousel--socials':
      _vm.mode === _vm.CarouselModes.CREATOR_SOCIALS,
    'carousel--preview': _vm.mode === _vm.CarouselModes.PREVIEW,
    'carousel--hover': _vm.isHoverMode
  },style:(_vm.cssVars)},[(_vm.showHeader)?_c('div',{staticClass:"carousel__header",style:({
      paddingBottom: _vm.headerPaddingBottom
    })},[_c('div',{staticClass:"carousel__header-title"},[_vm._t("title")],2),_vm._v(" "),(_vm.seeAllLink)?_c('div',{staticClass:"carousel__header-action"},[_c('NuxtLink',{attrs:{"to":_vm.localePath(_vm.seeAllLink)}},[_vm._v(" See all ")])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"carousel__btn"},[_c('div',{staticClass:"carousel__btn-prev",attrs:{"id":(_vm.id + "-carousel-prev")}},[_vm._v("<")]),_vm._v(" "),_c('div',{staticClass:"carousel__btn-next ml-2",attrs:{"id":(_vm.id + "-carousel-next")}},[_vm._v("\n        >\n      ")])])]):_vm._e(),_vm._v(" "),(_vm.descriptionVisible)?_c('div',{staticClass:"carousel__description"},[_vm._t("subtitle")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"swiper",attrs:{"id":_vm.id}},[(_vm.isPreview)?_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.images),function(image,index){return _c('div',{key:image._id,staticClass:"swiper-slide carousel__item",on:{"click":function($event){return _vm.handleItemClick(image)}}},[_c('div',{staticClass:"carousel__item-img"},[(_vm.isBannerVisible(index))?_c('img',{staticClass:"carousel__item-img__banner",attrs:{"src":require("@/assets/icons/pre-order-badge.svg"),"alt":"banner"}}):_vm._e(),_vm._v(" "),_c('CloudinaryImage',{staticClass:"carousel__item-img__picture",attrs:{"id":("image-" + (image._id)),"url":image.url,"alt":("product-preview-" + (image._id)),"modifiers":{
              gravity: 'face',
              roundCorner: _vm.imgBorderRadius
            }}})],1)])}),0):_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"swiper-slide carousel__item",on:{"click":function($event){return _vm.handleItemClick(item)}}},[_c('div',{staticClass:"carousel__item-img"},[(_vm.isItemPreOrder(item))?_c('img',{staticClass:"position-absolute",attrs:{"src":require("@/assets/icons/pre-order-badge.svg"),"alt":"pre-order now"}}):_vm._e(),_vm._v(" "),(_vm.isProductDigitalOnly(item))?_c('img',{staticClass:"carousel__item-digital-badge",attrs:{"src":require("@/assets/icons/pdf-badge.svg"),"alt":"digital-format"}}):_vm._e(),_vm._v(" "),(_vm.mode === _vm.CarouselModes.CREATOR_SOCIALS)?_c('img',{attrs:{"src":item.thumbnail_url,"alt":("post-" + (_vm.$get(item, 'title.short'))),"width":_vm.cssVars['--img-width'],"height":_vm.cssVars['--img-height']}}):_c('CloudinaryImage',{staticClass:"carousel__item-img__picture",attrs:{"id":("product-" + (item.name)),"url":_vm.getPreviewImage(item),"alt":("product-" + (item.name)),"width":_vm.cssVars['--img-width'],"height":_vm.cssVars['--img-height'],"modifiers":{
              gravity: 'face',
              roundCorner: _vm.imgBorderRadius
            }}})],1),_vm._v(" "),_c('div',{staticClass:"carousel__item-title"},[_vm._t("itemTitle",function(){return [(_vm.mode === _vm.CarouselModes.PRODUCT)?_c('div',[_vm._v(_vm._s(item.name))]):_vm._e()]},{"item":item})],2),_vm._v(" "),_c('div',{staticClass:"carousel__item-subtitle"},[_vm._t("itemSubtitle",function(){return [(_vm.mode === _vm.CarouselModes.PRODUCT)?_c('ProductPrice',{attrs:{"item":item}}):_vm._e()]},{"item":item})],2),_vm._v(" "),_c('div',{staticClass:"carousel__item-action"},[_vm._t("itemAction",function(){return [(_vm.showDefaultItemActions)?_c('div',[_c('Button',{staticClass:"w-100",attrs:{"full-width":"","loading":_vm.isAddToBasketLoading,"variant":"outline"},on:{"click":function($event){return _vm.handleAddToBasketClick(item)}}},[_vm._v("\n                Add to basket\n              ")])],1):_vm._e()]},{"item":item})],2)])}),0)]),_vm._v(" "),_c('b-modal',{attrs:{"centered":"","size":"sm","return-focus":"carousel","modal-footer":false,"hide-footer":true,"content-class":"social-post-modal","modal-class":"social-post-dialog"},model:{value:(_vm.showSocialPost),callback:function ($$v) {_vm.showSocialPost=$$v},expression:"showSocialPost"}},[_c('b-overlay',{staticClass:"social-post-modal__loader",attrs:{"show":_vm.isSocialLoading,"opacity":"1","spinner-variant":"dark"}},[(_vm.socialPost)?_c('iframe',{staticClass:"social-post-modal__content",attrs:{"src":_vm.socialPost.postURL,"srcdoc":_vm.socialPost.html},on:{"load":_vm.onSocialLoad}}):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }